import React from "react";
import * as styles from "./advertise.module.css";

const Advertise = () => {
  return (
    <>
      <div className={styles.mainSection}>
        <div className={styles.contentWrap}>
          <div className={styles.content}>
            <h1>
              <span>Advertise On Sleepless Yogi</span>
            </h1>
            <h3>The #1 community of top solopreneurs.</h3>
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <h2>Sleepless Yogi Sponsorship Opportunities</h2>
        <div className={styles.contentWrap}>
          <div className={styles.content}>
            <p>
              We offer various sponsored promotional packages to help your
              businesses increase their brand awareness and reach their target
              audience.
            </p>
            <p>Choose from a range of options that best suit your needs:</p>
            <ul>
              <li>Site - main page banner</li>
              <li>Article - header section</li>
              <li>Article - side bar</li>
              <li>Article - publish new article with do-follow link</li>
              <li>Article - add a link on my existing article</li>
              <li>Newsletter - header section</li>
              <li>Newsletter - bottom call to action</li>
              <li>
                [Bonus Boost] - on my{" "}
                <a
                  href="https://www.instagram.com/sleeplessyogi"
                  target="_blank"
                  title="Instagram"
                >
                  <span>Instagram</span>
                </a>{" "}
                and{" "}
                <a
                  href="https://twitter.com/sleeplessyogi"
                  target="_blank"
                  title="Twitter"
                >
                  <span>Twitter</span>
                </a>{" "}
                accounts
              </li>
              <li>[Guest Post] - hire me to write a post for you!</li>
            </ul>
            <p className="font-bold text-xl	">Reserve Your Ad Today!</p>
            <p>More details, any questions, or want to book?</p>
            <p>
              Email{" "}
              <a
                href="mailto:sleeplessyogi@gmail.com"
                target="_blank"
                title="Email"
              >
                sleeplessyogi@gmail.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Advertise;
