import React from "react";
import Footer from "../home/footer/Footer";
import Navbar from "../home/navbar/Navbar";
import Advertise from "./advertise";
import SEO from "../Seo";
import * as styles from "./advertise.module.css";

const Index = () => {
  return (
    <div className={styles.componentWrapper}>
      <SEO titleStub={`Advertise`} urlStub={`/advertise/`} />
      <Navbar />
      <Advertise />
      <Footer />
    </div>
  );
};

export default Index;
